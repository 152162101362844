.Index {
  background-color: aliceblue;
  min-height: 100vh;
  font-family: 'Noto Sans KR', sans-serif;
}

.header {
  box-shadow: 0 4px 16px 0 #00000010;
  margin-bottom: 2rem;
}

.box {
  min-height: 100vh;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 0 8px 0px #00000010;
  background-color: rgba(255, 255, 255, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.Index section {
  margin: 1rem 2.5rem;
  padding: 1rem;
  background: white;
}

.title {
  line-height: 1.2;
  margin-top: 0;
  padding-top: 0;
}

.title p:first-child {
  letter-spacing: 8px;
  font-family: 'Noto Serif KR', serif;
  font-size: 1.15rem;
}

.title p:last-child {
  font-size: 3rem;
  font-family: 'Song Myung', serif;
  font-weight: bold;
}

.ad p {
  border-bottom: 2px solid lightsteelblue;
  width: fit-content;
  font-size: 3rem;
  font-weight: 300;
  letter-spacing: -3px;
  line-height: 1;
  margin-bottom: 1rem;
}

.replace::before {
  content: '맞는';
  transition: all 0.5s ease-in-out;
}

.replace:hover::before {
  content: '맞추는';
  transition: all 0.5s ease-in-out;
}

.ornament {
  height: 4rem;
  margin: 0 auto;
  margin-bottom: 2rem;
}

.sample_preview_mockup {
  overflow-y: auto;
  border: 6px solid black;
  border-radius: 2.8rem;
  max-width: 375px;
  height: 667px;
  margin: 3rem auto !important;
  text-align: center;
  box-shadow: 0 2px 4px 1px #00000040;
  padding: 0 !important;
  position: relative;
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%0Awidth='60'%0Aheight='60'%0A%3E%3Ccircle cx='30' cy='30' r='15' stroke='%23000' stroke-opacity='0.2' stroke-width='1' fill='%23000' fill-opacity='0.3'/%3E%3C/svg%3E");
}

.sample_preview_mockup::-webkit-scrollbar {
  width: 0;
}

.sample_preview_mockup .templates_contents {
  position: absolute;
  top: 0;
  border-radius: calc(2.8rem - 6px);
  width: 100%;
  text-align: center;
  box-shadow: inset 0 0 12px 0px #00000030;
  background-color: lightsteelblue;
}

.sample_preview_mockup .templates_contents p {
  background-color: black;
  width: 90px;
  height: 28px;
  border-radius: 100px;
  margin: 6px auto;
  text-align: right;
  padding: 0 4px;
  position: sticky;
  top: 6px;
}

.sample_preview_mockup h3 {
  border-bottom: 1px solid #eee;
  padding: 1.5rem 0;
  font-size: 3rem;
  font-family: 'Hurricane', serif;
  color: white;
  font-weight: 500;
  text-shadow: 2px 4px 1px #00000030;
}

.sample_preview_mockup .buttons_wrapper {
  background-color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 2.5rem 2rem;
  box-shadow: inset 0 8px 16px -4px #00000010;
}

.sample_preview_mockup button span {
  color: white;
  font-size: 0.8rem;
  background: linear-gradient(0deg, #0009, transparent);
  width: 100%;
  display: block;
  position: absolute;
  bottom: 0;
  height: 5rem;
  padding-top: 3rem;
  cursor: pointer;
}

.sample_preview_mockup button {
  border-radius: 0;
  height: 12rem;
  padding: 0;
  position: relative;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.sample_preview_mockup button:hover {
  box-shadow: 0 0 8px 0px #00000030;
  scale: 1.1;
}

button.disabled {
  background-color: #ccc;
  color: #fefefe;
}

button.disabled:hover {
  background-color: #aaa;
}

.custom {
  grid-column-start: span 2;
  font-family: 'Noto Serif KR', serif;
  font-style: italic;
  font-size: 0.9rem;
  padding-top: 1.5rem;
  border-top: 1px solid lightsteelblue;
}
.custom span {
  background-color: lightpink;
  /* color: white; */
  font-weight: bold;
  padding: 0 4px;
}
.dots {
  display: grid;
  line-height: 0.75;
  margin-bottom: 2rem;
}

.dots span {
  color: lightsteelblue;
  font-size: 1.8rem;
}

.notice {
  line-height: 2;
  font-family: 'Noto Serif KR', serif;
}

.footer {
  background-color: lightsteelblue;
  padding: 2rem;
  box-shadow: 0 -6px 24px -12px #00000010;
  font-size: 0.9rem;
  line-height: 1.6;
  margin-top: 1rem;
  color: white;
}

.footer span {
  cursor: pointer;
  font-weight: bold;
}

.footer span:hover {
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 440px) {
  .Index section {
    margin: 1rem 1.5rem;
  }

  .ad p {
    font-size: 2.8rem;
  }

  .title p:first-child {
    letter-spacing: 5px;
    font-size: 1rem;
  }

  .title p:last-child {
    font-size: 2.4rem;
  }
}
