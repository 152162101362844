.App {
  text-align: center;
}

::-webkit-scrollbar {
  width: 12px;
  transition: all 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.5);
}

::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #ddd;
}

@media (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0px;
    transition: all 0.5s ease-in-out;
  }
}
