.Loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes ld-fall {
  0% {
    transform: translate(0, 0px) rotate3d(1, 1, 1, 0deg);
  }
  16.6% {
    transform: translate(0, 161.66666666666666px) rotate3d(1, 1, 1, 180deg);
  }
  33.3% {
    transform: translate(0, 323.3333333333333px) rotate3d(1, 1, 1, 360deg);
  }
  50.0% {
    transform: translate(0, 485px) rotate3d(1, 1, 1, 540deg);
  }
  66.6% {
    transform: translate(0, 646.6666666666666px) rotate3d(1, 1, 1, 720deg);
  }
  83.3% {
    transform: translate(0, 808.3333333333334px) rotate3d(1, 1, 1, 900deg);
  }
  100% {
    transform: translate(0, 970px) rotate3d(1, 1, 1, 1080deg);
  }
}
.ld.ld-fall {
  animation: ld-fall 4s linear infinite;
}
.ld.ld-fall2 {
  animation: ld-fall 4s linear infinite;
  animation-fill-mode: forwards;
}
